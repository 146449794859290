.form{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

input{
    width: 60%;
    padding: 10px;
    border: 1px solid gray;
}

.formbutton{
    border: none;
    padding: 10px;
    background-color: black;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    width: 60%;
    transition: .5s ease-in-out;
}


.formbutton:hover{
    background-color: rgb(205, 205, 205);
    color: black;
    transition: .5s ease-in-out;
}


.buttonclick{
    padding: 10px;
    flex:1;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #555;
}

.admin{
    display: flex;
    align-items: center;
    gap: 2px;
    color: rgb(0, 0, 0);
}
