/*img{
    width: 1510px;
    height: 400px;
}*/

.place{
    display: flex;
    flex-direction: column;
}

.banner img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.banner h1{
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: -180px;
    margin-bottom: 150px;
    z-index: 40;
    
}

.color{
    width: 100%;
    height: 300px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1;
}