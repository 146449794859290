/*.App{
    height: 100vh;
    padding: 0px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}*/

.work{ 
    display: flex;
    flex-wrap: wrap;
}


.locations img{
    width: 350px;
    height: 200px;
    object-fit: cover;
    border-radius: 6px;
}

.locations{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1{
    text-align: center;
}

.go{
    border: none;
    padding: 3px 6px;
    background-color: rgba(255, 255, 255, 0.933);
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.933);
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.525);
    transition: .5s;
}


.go:hover{
    background-color: rgb(0, 0, 0);
    transition: .5s;
    color: white;
}


.banner{
    width: auto;
    height: auto;
}

.banner .picsure{
    object-fit: cover;
}