.shift{
   align-items: center;
    flex-wrap: wrap;
    display: flex;
}

.shifts{
    background-color: rgb(228, 228, 228);
    height: auto;
    width: auto;
    padding: 20px 20px 20px 20px;
    border-radius: 6px;
    flex-direction: column;
    margin: 20px 10px 10px;
    box-shadow: 5px 5px 5px rgba(95, 95, 95, 0.607);
    transition: .5s ease-out;
}

.shifts:hover{
    box-shadow: 10px 10px 10px rgba(95, 95, 95, 0.607);
    transition: 1s;
}
h2{
    color: rgb(0, 0, 0);
    text-align: center;
}
.pick{
    border: none;
    border-radius: 6px;
    background-color: black;
    color: white;
    cursor: pointer;
    box-shadow: 2px 2px 2px 0px rgba(95, 95, 95, 0.607);
    transition: .5s ease-out;
}

.pick:hover{
    box-shadow: 10px 10px 10px rgba(95, 95, 95, 0.607);
    transition: .5s ease;
}